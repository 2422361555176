// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// NEW THEME TO TEST OUT

:root {
  --ion-background-color: #fffef6;
  --ion-background-color-rgb: 255,254,246;

  --ion-text-color: #251e1b;
  --ion-text-color-rgb: 37,30,27;

  --ion-color-step-50: #f4f3eb;
  --ion-color-step-100: #e9e8e0;
  --ion-color-step-150: #dedcd5;
  --ion-color-step-200: #d3d1ca;
  --ion-color-step-250: #c9c6bf;
  --ion-color-step-300: #bebbb4;
  --ion-color-step-350: #b3b0a9;
  --ion-color-step-400: #a8a49e;
  --ion-color-step-450: #9d9993;
  --ion-color-step-500: #928e89;
  --ion-color-step-550: #87837e;
  --ion-color-step-600: #7c7873;
  --ion-color-step-650: #716c68;
  --ion-color-step-700: #66615d;
  --ion-color-step-750: #5c5652;
  --ion-color-step-800: #514b47;
  --ion-color-step-850: #46403c;
  --ion-color-step-900: #3b3431;
  --ion-color-step-950: #302926;

  --ion-color-primary: #745f46;
  --ion-color-primary-rgb: 116,95,70;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #66543e;
  --ion-color-primary-tint: #826f59;

  --ion-color-secondary: #5f6345;
  --ion-color-secondary-rgb: 95,99,69;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #54573d;
  --ion-color-secondary-tint: #6f7358;

  --ion-color-tertiary: #AF9287;
  --ion-color-tertiary-rgb: 175,146,135;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #9a8077;
  --ion-color-tertiary-tint: #b79d93;

  --ion-color-invisible: #fffef6;
  --ion-color-invisible-rgb: 255,254,246;
  --ion-color-invisible-contrast: #000000;
  --ion-color-invisible-contrast-rgb: 0,0,0;
  --ion-color-invisible-shade: #e0e0d8;
  --ion-color-invisible-tint: #fffef7;

  --ion-font-family: Optima, sans-serif;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint);
}

.ion-color-invisible {
  --ion-color-base: var(--ion-color-invisible);
  --ion-color-base-rgb: var(--ion-color-invisible-rgb);
  --ion-color-contrast: var(--ion-color-invisible-contrast);
  --ion-color-contrast-rgb: var(--ion-color-invisible-contrast-rgb);
  --ion-color-shade: var(--ion-color-invisible-shade);
  --ion-color-tint: var(--ion-color-invisible-tint);
}