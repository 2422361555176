/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/css/bundle';

ion-toolbar{    
    border: none !important;
    background-color: #fffef6 !important;
}

ion-menu-button{
    color: #745f46 !important;
}

ion-menu::part(container){
    max-width: 60%;
}

ion-tab-bar{
    border: none;
    min-height: 50px;
    max-height: 55px;
    background-color: #fffef6;
}

ion-title{
    background-color: #fffef6;
}

ion-text{
    font-size: 0.9em;
}

.app-title{
    text-align: center;
    font-weight: bolder;
}

.bottom-div{
    position: fixed;
    bottom: 30px;
    width: 100%;
    height: 55px;
    left: 4%;
    z-index: 100;
}

.bottom-tab-bar{
    border: 1.5px solid #AF9287;
    background-color: #AF9287 !important;
    border-radius: 16px;
    width: 92%;
}

.bottom-tab-button{
    background-color: #AF9287;
    color: #fffef6;
    border-radius: 16px;
}

.top-toolbar{
    // margin-top: 30px;
    background-color: #fffef6 !important;
}

.top-tab-button{
    position: relative;
    max-width: 72%;
    background-color: #fffef6 !important;
}
  
.logo-button{
    position: absolute;
    left: 2.5%;
    max-height: inherit;
    color: #AF9287;
    background-color: #fffef6 !important;
}
  
.home-logo{
    position: absolute;
    left: 2%;
    max-width: inherit;
    background-color: #fffef6 !important;
}

.home-icon{
    font-size: 44px;
    color: transparent;
}

.card{
    position: relative;
    width: 75%;
    height: 20%;
    margin:auto;
}

.card-title {
    position: absolute;
    top: 12%;
    text-align: center;
    font-size: 1.2em;
    width: 100%;
    font-weight: bold;
    text-shadow: -1.5px 1.5px #5f6345;
    color: #eee7d5;
}

.card-subtitle {
    position: absolute;
    top: 45%;
    left: 5%;
    text-align: center;
    font-size: 0.85em;
    width: 90%;
    text-shadow: -1px 1px #5f6345;
    color: #eee7d5;
}

.text-bold{
    font-weight: bold;
    font-size: 1.15em;
}

.form-label{
    position: relative;
    left: 5%
}

.form-item{
    position: relative;
    border: 1.5px solid #d6ac9d;
    border-radius: 16px;
    width: 80%;
    margin: auto
}

.form-error{
    position: relative;
    width: 70%;
    margin: auto;
    font-size: smaller;
}

.submit-button{
    position: relative;
    width: 36%;
    left: 32%;
}